import { useEffect } from "react";
import styled from "styled-components";
import Viewport from "../../utils/Viewport";
import Show from './Show'
import Box from "@mui/material/Box";
interface IHomePageProps {
  navBarOffset: number;
}

const DataPage: React.FC<IHomePageProps> = (props) => {
  const { navBarOffset } = props;
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();

  useEffect(() => {
    document.title = "DATA - TALK";
  }, []);

  return (
    <HeroContainer offset={navBarOffset}>
      <Box sx={{ backgroundColor: "#00000" }}>
        <Show></Show>
      </Box>
    </HeroContainer>
  );
};

const HeroContainer = styled.div<{ offset: number }>`
  margin-top: -${(props) => props.offset}px;
`;

export default DataPage;

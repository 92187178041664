import { Typography } from "@material-ui/core";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import DesktopHero from "../../assets/desktopHero.png";
import { Spacer } from "../../utils/Spacer";
import { Button } from "@material-ui/core";

import Viewport from "../../utils/Viewport";
import Roadmap from "./Roadmap";
import AboutUs from "./Story";
import FAQ from "./FAQ";
import Stack from "@mui/material/Stack";

interface IHomePageProps {
  navBarOffset: number;
}

const HomePage: React.FC<IHomePageProps> = (props) => {
  const { navBarOffset } = props;
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();

  useEffect(() => {
    document.title = "Home - TALK";
  }, []);

  const Hero = (): JSX.Element => (
    <>
      <HeroImage src={DesktopHero} alt="Steve Jobs image" />
      {isTabletAndAbove ? <Spacer height={150} /> : <Spacer height={40} />}
    </>
  );

  const Titles = (): JSX.Element => (
    <>
      <Title align="center" isTabletAndAbove={isTabletAndAbove}>
        “ Stay Hungry & Stay Foolish ”
      </Title>
      {isDesktopAndAbove ? <Spacer height={70} /> : <Spacer height={16} />}
    </>
  );

  return (
    <HeroContainer offset={navBarOffset}>
      <Hero />
      <Titles />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <TalkButton variant="contained" href="/talk">
          TALK NOW
        </TalkButton>
      </Stack>
      {isDesktopAndAbove ? <Spacer height={70} /> : <Spacer height={16} />}

      <AboutUs />
      <Roadmap />
      <FAQ />
      <Outlet />
    </HeroContainer>
  );
};

const TalkButton = styled(Button)`
  background-color: #f05454;
  width: 240px;
  height: 60px;
  font-size: 20px;
  font-weight: 600;
`;
const HeroImage = styled.img`
  width: 100%;
`;

const HeroContainer = styled.div<{ offset: number }>`
  margin-top: -${(props) => props.offset}px;
`;

const Title = styled(Typography)<{ isTabletAndAbove: boolean }>`
  font-weight: 900;
  // font-size: ${(props) => (props.isTabletAndAbove ? "40px" : "24px")};
  font-size: 28px;
  text-transform: capitalize;
`;

export default HomePage;

import { Row, SecondaryTitle, StyledColumn } from "../../Styles";
import Viewport from "../../utils/Viewport";
import styled from "styled-components";
import { Colors } from "../../utils/Colors";
import { Spacer } from "../../utils/Spacer";
import { Collapse, Typography } from "@material-ui/core";
import ExpandIcon from "../../assets/expandIcon.svg";
import ShrinkIcon from "../../assets/shrinkIcon.svg";
import { useState } from "react";
import { Element } from "react-scroll";

// component being placed outside of the FAQ component is because it prevents rendering animation
const QuestionTag: React.FC<{
  question: string;
  answer: JSX.Element;
  expand: boolean;
  isDesktopAndAbove: boolean;
  isTabletAndAbove: boolean;
  onExpand: () => void;
  onShrink: () => void;
}> = ({
  question,
  answer,
  expand,
  isDesktopAndAbove,
  isTabletAndAbove,
  onExpand,
  onShrink,
}) => {
  return (
    <StyledQuestionTag
      isDesktopAndAbove={isDesktopAndAbove}
      onClick={expand ? onShrink : onExpand}
    >
      <TitleContainer>
        <QuestionTitle isTabletAndAbove={isTabletAndAbove}>
          {question}
        </QuestionTitle>
        {expand ? (
          <StyledIcon
            src={ShrinkIcon}
            alt="shrink icon"
            isTabletAndAbove={isTabletAndAbove}
          />
        ) : (
          <StyledIcon
            src={ExpandIcon}
            alt="expand icon"
            isTabletAndAbove={isTabletAndAbove}
          />
        )}
      </TitleContainer>
      <Collapse in={expand}>
        <div className="collapsible">
          <Spacer height={16} />
          {isTabletAndAbove ? <Spacer height={8} /> : null}
          {answer}
          {isTabletAndAbove ? <Spacer height={16} /> : <Spacer height={8} />}
        </div>
      </Collapse>
    </StyledQuestionTag>
  );
};

const FAQ: React.FC = () => {
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();
  const [isExpand, setIsExpand] = useState(-1);

  const q1 = "Question 1: ...?";
  const a1 = (
    <>
      <QuestionContent isTabletAndAbove={isTabletAndAbove}>
        A: ...
      </QuestionContent>
    </>
  );

  const q2 = "Question 2: ...?";
  const a2 = (
    <>
      <QuestionContent isTabletAndAbove={isTabletAndAbove}>
        A: ...
      </QuestionContent>
    </>
  );

  const q3 = "How Can I be part of the Talk Community";
  const a3 = (
    <QuestionContent isTabletAndAbove={isTabletAndAbove}>
      Follow us on{" "}
      <a href="https://twitter.com" style={{ textDecoration: "underline" }}>
        Twitter
      </a>
      , join us in{" "}
      <a href="https://discord.gg" style={{ textDecoration: "underline" }}>
        Discord
      </a>
      !
    </QuestionContent>
  );

  type Question = {
    question: string;
    answer: JSX.Element;
  };

  const questions: Question[] = [
    {
      question: q1,
      answer: a1,
    },
    {
      question: q2,
      answer: a2,
    },
    {
      question: q3,
      answer: a3,
    },
  ];

  const onExpand = (id: number) => {
    setIsExpand(id);
  };

  const onShrink = () => {
    setIsExpand(-1);
  };

  return (
    <Element name="faq">
      <StyledColumn
        isTabletAndAbove={isTabletAndAbove}
        isDesktopAndAbove={isDesktopAndAbove}
      >
        <SecondaryTitle isTabletAndAbove={isTabletAndAbove} align="center">
          FAQ
        </SecondaryTitle>
        <Spacer height={30} />
        {isDesktopAndAbove ? <Spacer height={50} /> : null}
        {questions.map((question, idx) => (
          <>
            <QuestionTag
              question={question.question}
              answer={question.answer}
              key={idx}
              expand={isExpand == idx}
              isDesktopAndAbove={isDesktopAndAbove}
              isTabletAndAbove={isTabletAndAbove}
              onExpand={() => {
                onExpand(idx);
              }}
              onShrink={onShrink}
            />
            {isTabletAndAbove ? <Spacer height={24} /> : <Spacer height={14} />}
          </>
        ))}
      </StyledColumn>
    </Element>
  );
};

export default FAQ;

const StyledQuestionTag = styled.div<{
  isDesktopAndAbove: boolean;
}>`
  width: 100%;
  max-width: 1170px;
  background-color: ${Colors.WHITE};
  border-radius: ${({ isDesktopAndAbove }) =>
    isDesktopAndAbove ? "20px" : "13px"};
  padding: ${({ isDesktopAndAbove }) =>
    isDesktopAndAbove ? "26px 40px" : "13px 26px"};
`;

const TitleContainer = styled(Row)`
  justify-content: space-between;
`;

const QuestionTitle = styled(Typography)<{ isTabletAndAbove: boolean }>`
  color: ${Colors.BLACK};
  font-size: ${({ isTabletAndAbove }) => (isTabletAndAbove ? "24px" : "14")};
  font-weight: 700;
`;

const QuestionContent = styled(Typography)<{ isTabletAndAbove: boolean }>`
  color: ${Colors.BLACK};
  font-size: ${({ isTabletAndAbove }) => (isTabletAndAbove ? "20px" : "12PX")};
`;

const StyledIcon = styled.img<{ isTabletAndAbove: boolean }>`
  width: ${({ isTabletAndAbove }) => (isTabletAndAbove ? "30px" : "16px")};
  cursor: pointer;
`;

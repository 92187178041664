import styled from "styled-components";
import { Box } from "@mui/material";
import { Element } from "react-scroll";
import Viewport from "../../utils/Viewport";
import { Avatar } from "@mui/material";
import { Typography } from "@mui/material";
import { TextField } from "@material-ui/core";
import { Spacer } from "../../utils/Spacer";
import SendIcon from "@mui/icons-material/Send";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import React, { useState, useEffect, useRef } from "react";
const { Configuration, OpenAIApi } = require("openai");

const useDidMountEffect = (func: any, deps: any) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

const previewContext = [{ from: "BOT2", text: "What do you want to ask?" }];

interface IUserInput {
  from: string;
  text: string;
}

const configuration = new Configuration({
  apiKey: process.env.REACT_APP_API_KEY,
});
const openai = new OpenAIApi(configuration);

async function answerText(text: string) {
  const completion = await openai.createCompletion({
    model: "davinci:ft-personal-2022-12-16-02-57-17",
    prompt: text,
    max_tokens: 70,
    temperature: 0.6,
  });
  return completion.data.choices[0].text;
}


const TextArea: React.FC = () => {
  const [userInputs, setUserInputs] = useState<IUserInput[]>(previewContext);
  const [textInput, setTextInput] = useState("");
  const [botAnswer, setBotAnswer] = useState("");
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
  };

  const handleClick = async () => {
    if (textInput === "") {
      return;
    }

    setUserInputs([...userInputs, { from: "USER", text: textInput }]);
    setTextInput("");
    const answer = await answerText(textInput);
    setBotAnswer(answer.slice(0, answer.search("END")));
  };

  useDidMountEffect(() => {
    setUserInputs([...userInputs, { from: "BOT1", text: botAnswer }]);
  }, [botAnswer]);


  useEffect(() => {
    scrollToBottom();
  }, [userInputs]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextInput(e.target.value);
  };

  const RenderText = (text: string, from: string) => (
    <>
      <Box sx={from !== "USER" ? JobsBoxStyle : UserBoxStyle}>
        {from === "BOT1" ? (
          <Avatar sx={JobsAvatarStyle} style={{ alignSelf: "flex-start" }}>
            J
          </Avatar>
        ) : from === "BOT2" ? (
          <Avatar sx={JobsAvatarStyle} style={{ alignSelf: "flex-start" }}>
            J
          </Avatar>
        ) : (
          <Avatar sx={UserAvatarStyle} style={{ alignSelf: "flex-start" }}>
            U
          </Avatar>
        )}

        <Spacer width={12} />
        <Typography style={{ wordWrap: "break-word" }}>{text}</Typography>
      </Box>
      <Spacer height={16} />
    </>
  );

  return (
    <Element name="TextHistory">
      <Box sx={MainContainer}>
        <Box sx={TextHistoryContainer} ref={messagesEndRef}>
          {userInputs.map((input) => RenderText(input.text, input.from))}
        </Box>

        <Box sx={{ mx: 2 }}>
          <TextFieldWrapper
            fullWidth
            multiline
            color="secondary"
            variant="outlined"
            rows={1}
            focused
            onChange={handleOnChange}
            value={textInput}
            InputProps={{
              style: {
                color: "white",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    style={{
                      color: "#DA0037",
                    }}
                    onClick={handleClick}
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </Element>
  );
};

export default TextArea;

const MainContainer = {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  width: "60vw",
  height: "90vh",
};

const TextHistoryContainer = {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  overflow: "scroll",
  width: "60vw",
  p: 2,
};
const JobsBoxStyle = {
  maxWidth: 400,
  flexDirection: "row",
  backgroundColor: "#EDEDED",
  color: "#000000",
  display: "inline-flex",
  p: 1,
  borderRadius: 5,
  alignItems: "center",
  width: "fit-content",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  paddingRight: "24px",
};

const UserBoxStyle = {
  maxWidth: 400,
  flexDirection: "row-reverse",
  backgroundColor: "#DEF5E5",
  color: "#000000",
  display: "inline-flex",
  p: 1,
  borderRadius: 5,
  alignItems: "center",
  width: "fit-content",
  alignSelf: "flex-end",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  paddingLeft: "24px",
};

const JobsAvatarStyle = {
  backgroundColor: "#30475E",
};


const UserAvatarStyle = {
  backgroundColor: "#FEBE8C",
};

export const TextFieldWrapper = styled(TextField)`
  fieldset {
    border-radius: 15px;
  },
`;

import Viewport from "../../utils/Viewport";
import {
  TalkColumn,
  BlueTitle,
  PlayGroundMenuText,
  MenuText,
} from "../../Styles";
import { Element } from "react-scroll";
import { Box } from "@mui/material";
import Slider from "@mui/material/Slider";
import * as React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import styled from "styled-components";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { Spacer } from "../../utils/Spacer";

const MenuSlider = styled(Slider)`
  color: #f05454 !important;
  my: 2;
`;

const StyleButton = styled(Button)`
  minwidth: "250px";
  background-color: #f05454 !important;
`;

const ControlMenu: React.FC = () => {
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();

  const [TempValue, setTempValue] = React.useState<number>(0.9);
  const [maximumLength, setmaximumLength] = React.useState<number>(50);
  const [model, setmodel] = React.useState("text-davinci-002");
  const [frequency, setFrequency] = React.useState<number>(0.0);
  const [Presence, setPresence] = React.useState<number>(0.0);


  const handleTempChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setTempValue(newValue);
    }
  };

  const handleMaximumLengthChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    if (typeof newValue === "number") {
      setmaximumLength(newValue);
    }
  };
  const handleFrequencyChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setFrequency(newValue);
    }
  };

  const handlePresenceChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setPresence(newValue);
    }
  };

  const handleModelChange = (event: SelectChangeEvent) => {
    setmodel(event.target.value as string);
  };

  return (
    <Element name="TextHistory">
      <Box sx={controlBoxStyle}>
        <Box sx={{ width: 250, mt: 5, mb: 2 }}>
          <PlayGroundMenuText isTabletAndAbove={isTabletAndAbove}>
            Model:
          </PlayGroundMenuText>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue="text-davinci-002"
              value={model}
              label="text-davinci-002"
              variant="standard"
              onChange={handleModelChange}
            >
              <MenuItem value={"text-davinci-002"}>text-davinci-002</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ width: 250 }}>
          <PlayGroundMenuText isTabletAndAbove={isTabletAndAbove}>
            Temperature: {TempValue}
          </PlayGroundMenuText>
          <MenuSlider
            size="small"
            value={TempValue}
            min={0.1}
            step={0.1}
            max={0.5}
            color="primary"
            onChange={handleTempChange}
            valueLabelDisplay="auto"
            aria-labelledby="non-linear-slider"
          />
        </Box>

        <Box sx={{ width: 250 }}>
          <PlayGroundMenuText isTabletAndAbove={isTabletAndAbove}>
            Maximum Length: {maximumLength}
          </PlayGroundMenuText>
          <MenuSlider
            size="small"
            value={maximumLength}
            min={50}
            step={10}
            max={100}
            onChange={handleMaximumLengthChange}
            valueLabelDisplay="auto"
            aria-labelledby="non-linear-slider"
          />
        </Box>

        <Box sx={{ width: 250, my: 1 }}>
          <PlayGroundMenuText isTabletAndAbove={isTabletAndAbove}>
            Stop Sequence:
          </PlayGroundMenuText>
          <Input sx={{ width: 250 }} defaultValue="\n, END" />
        </Box>

        <Box sx={{ width: 250, my: 1 }}>
          <PlayGroundMenuText isTabletAndAbove={isTabletAndAbove}>
            Frequency penalty: {frequency}
          </PlayGroundMenuText>
          <MenuSlider
            size="small"
            value={frequency}
            min={0}
            step={0.1}
            max={2}
            onChange={handleFrequencyChange}
            valueLabelDisplay="auto"
            aria-labelledby="non-linear-slider"
          />
        </Box>

        <Box sx={{ width: 250 }}>
          <PlayGroundMenuText isTabletAndAbove={isTabletAndAbove}>
            Presence penalty: {Presence}
          </PlayGroundMenuText>
          <MenuSlider
            size="small"
            value={Presence}
            min={0}
            step={0.1}
            max={2}
            onChange={handlePresenceChange}
            valueLabelDisplay="auto"
            aria-labelledby="non-linear-slider"
          />
        </Box>

        <Spacer height={28}></Spacer>
        <StyleButton variant="contained" sx={{ width: 250 }}>
          Submit Model
        </StyleButton>

        <Spacer height={28}></Spacer>
        <StyleButton variant="contained" sx={{ width: 250 }}>
          Download Conversation
        </StyleButton>
      </Box>
    </Element>
  );
};

const controlBoxStyle = {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#444444",
  width: "300px",
  alignItems: "center",
  height: "90vh",
  mx: 4,
  borderRadius: "16px",
  boxShadow: "rgba(0, 0, 0, 0.3) 0px 4px 12px",
};

export default ControlMenu;

import Viewport from "../../utils/Viewport";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GroupsIcon from "@mui/icons-material/Groups";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import styled from "styled-components";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import { FinetuneText } from "../../Styles";

const StyleButton = styled(Button)`
  color: #fff !important;
  background-color: #f05454 !important;
  height: 46px;

`;

const FinetuneBoxStyle = {
  display: "flex",
  flexDirection: "column",
  width: "800px",
  alignItems: "left",
  height: "90vh",
  mx: 4,
  mt: 2,
};

const StyleTextField = styled(TextField)`
  minwidth: "250px";
  background-color: #ffff !important;
  color: #fff
`;

const Finetune: React.FC = () => {
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();
  const [model, setmodel] = React.useState("text-davinci-002");
  const handleModelChange = (event: SelectChangeEvent) => {
    setmodel(event.target.value as string);
  };

  return (
    <Box sx={FinetuneBoxStyle}>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <StyleTextField
          required
          id="standard-search"
          label="Dataset ID"
          type="search"
          variant="standard"
        />
        <StyleTextField
          required
          id="standard-search"
          label="Data Type"
          type="search"
          variant="standard"
        />
      </Box>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <StyleTextField
            required
            id="standard-required"
            label="Base Model"
            defaultValue="text-davinci-002"
            variant="standard"
          />
          <StyleTextField
            id="standard-password-input"
            label="API-key"
            type="password"
            autoComplete="current-password"
            variant="standard"
          />
          <StyleTextField
            disabled
            id="standard-number"
            label="Epoches"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
          

          <StyleTextField
            id="standard-number"
            label="Prices"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
          />
        </div>
      </Box>
      <Box sx={{ my: 1, mx: 1 }}>
        <StyleButton>Generate Model</StyleButton>
      </Box>
    </Box>
  );
};

export default Finetune;

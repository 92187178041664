import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../../utils/Colors";
import { Spacer } from "../../utils/Spacer";
import DiscordIcon from "../../assets/discordIcon.svg";
import TwitterIcon from "../../assets/twitterIcon.svg";
import Openai from "../../assets/OpenAI.svg";
import React from "react";
import { Link as MUILink } from "@mui/material";
import { Link as ScrollLink } from "react-scroll";
import { Collapse } from "@material-ui/core";
import { Column, Row } from "../../Styles";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import Logo from "../../assets/logo.png";

const NavLink: React.FC<{
  fontWeight?: string;
  letterSpacing?: string;
  fontSize?: string;
  onClick?: () => void;
}> = ({ fontWeight, letterSpacing, fontSize, children, onClick }) => (
  <Button onClick={onClick}>
    <NavText
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      fontSize={fontSize}
    >
      {children}
    </NavText>
  </Button>
);

export const SocialLinks = (): JSX.Element => (
  <Row>
    <a href="https://discord.com/">
      <LinkButton>
        <img src={DiscordIcon} />
      </LinkButton>
    </a>
    <Spacer width={24} />
    <a href="https://twitter.com">
      <LinkButton>
        <img src={TwitterIcon} />
      </LinkButton>
    </a>
    <Spacer width={24} />
    <a href="https://openai.com/api/">
      <LinkButton>
        <img src={Openai} />
      </LinkButton>
    </a>
  </Row>
);

export const MobileNavBar: React.FC<{
  isOpen: boolean;
  onClick: () => void;
  src: string;
}> = ({ isOpen, onClick, src }) => (
  <>
    <Link to="/">
      <NavLink fontWeight="900" fontSize="28px" letterSpacing="1px">
        TALK
      </NavLink>
    </Link>
    <AnimatedMenuIcon isOpen={isOpen} onClick={onClick} src={src} />
  </>
);

export const MobileNavDropdown: React.FC<{
  isOpen: boolean;
  navBarHeight: number;
  onClick: () => void;
}> = ({ isOpen, navBarHeight, onClick }) => (
  <Collapse in={isOpen}>
    <MobileNavDropdownContainer navBarHeight={navBarHeight} align="center">
      <Spacer height={40} />
      <ScrollLink
        to="story"
        smooth
        onClick={onClick}
        offset={window.innerHeight * -1}
      >
        <NavLink fontWeight="900" fontSize="20">
          STORY
        </NavLink>
      </ScrollLink>
      <Spacer height={40} />
      <ScrollLink
        to="roadmap"
        smooth
        onClick={onClick}
        offset={window.innerHeight * -1}
      >
        <NavLink fontWeight="900" fontSize="20">
          ROADMAP
        </NavLink>
      </ScrollLink>
      <Spacer height={40} />
      <ScrollLink
        to="faq"
        smooth
        onClick={onClick}
        offset={window.innerHeight * -1}
      >
        <NavLink fontWeight="900" fontSize="20">
          FAQ
        </NavLink>
      </ScrollLink>
      <Spacer height={40} />
      <NavLink fontWeight="900" fontSize="20">
        <a href="https://launchmynft.io/collections/8GpHwKRLn6HmrayHEStzCSZ7SBT4gKAaAUaXqUoimuvy/c0ROpfT7rcQFccY3GAfI">
          TALK
        </a>
      </NavLink>
      <Spacer height={80} />
      <SocialLinks />
    </MobileNavDropdownContainer>
  </Collapse>
);

export const DesktopNavBar = (): JSX.Element => (
  <>
    <NavBarSubContainer>
      <LogoPlaceHolder src={Logo} />
      <Spacer width={12} />
      <Link to="/">
        <NavLink fontWeight="900" fontSize="28px" letterSpacing="1px">
          TALK
        </NavLink>
      </Link>
      <Spacer width={56} />
      <MUILink
        color="inherit"
        noWrap
        key={"Data"}
        variant="body2"
        href={"/data"}
        underline="hover"
        sx={{ p: 1, flexShrink: 0 }}
      >
        Data
      </MUILink>

      <Spacer width={12} />

      <MUILink
        color="inherit"
        noWrap
        key={"Playground"}
        variant="body2"
        underline="hover"
        href={"/playground"}
        sx={{ p: 1, flexShrink: 0 }}
      >
        Playground
      </MUILink>

      <Spacer width={12} />

      <MUILink
        color="inherit"
        noWrap
        key={"Story"}
        variant="body2"
        underline="hover"
        href={"/"}
        sx={{ p: 1, flexShrink: 0 }}
      >
        Story
      </MUILink>
    </NavBarSubContainer>

    <NavBarSubContainer>
      <SocialLinks />
      <Spacer width={24} />
      <ContainedButton backgroundColor={Colors.OHNI} color={Colors.BLACK}>
        <MintingText>
          <a href="/login">Log In</a>
        </MintingText>
      </ContainedButton>
      <Spacer width={16} />
      <ContainedButton backgroundColor={Colors.TOHOH} color={Colors.BLACK}>
        <MintingText>
          <a href="/talk">TALK</a>
        </MintingText>
      </ContainedButton>
    </NavBarSubContainer>
  </>
);

const MobileNavDropdownContainer = styled(Column)<{ navBarHeight: number }>`
  height: 100vh;
  z-index: 100;
`;

const AnimatedMenuIcon = styled.img<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? "rotate(30deg)" : "rotate(0deg)")};

  transition: all 0.3s ease-in-out;
`;

const NavBarSubContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button<{ color?: string; backgroundColor?: string }>`
  border: unset;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "unset"};
  color: ${(props) => (props.color ? props.color : Colors.WHITE)};
  &:hover {
    cursor: pointer;
    font-weight: 500;
  }
  padding: 0;
`;

const NavText = styled(Typography)<{
  fontWeight?: string;
  fontSize?: string;
  letterSpacing?: string;
}>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "unset"};
`;

const ContainedButton = styled(Button)`
  padding: 6px 12px;
  gap: 10px;

  width: 84px;
  height: 36px;
  border-radius: 6px;
`;

const MintingText = styled(Typography)`
  font-weight: 900;
`;

const LinkButton = styled(Button)`
  border-radius: 50%;
  width: 24px;
  height 24px;
  background-color: ${Colors.WHITE};
  display:flex;
  justify-content: center;
  align-items: center;
`;

const LogoPlaceHolder = styled.img`
  height: 36px;
  width: 36px;
`;

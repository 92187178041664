import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MainFeaturedPost from "./MainPoster";
import FeaturedPost from "./FeaturePost";
import SJ from "../../assets/stjobs.jpg";
import Poster1 from "../../assets/login3.jpg";
import Poster2 from "../../assets/dis.jpg";
import Header from "./Nav"
const sections = [
  { title: "Technology", url: "#" },
  { title: "Design", url: "#" },
  { title: "Culture", url: "#" },
  { title: "Business", url: "#" },
  { title: "Politics", url: "#" },
  { title: "Opinion", url: "#" },
  { title: "Science", url: "#" },
  { title: "Health", url: "#" },
  { title: "Style", url: "#" },
  { title: "Travel", url: "#" },
];

const mainFeaturedPost = {
  title: "GPT-3 Based Steve Jobs Style AI",
  description:
    "Open-AI GPT-3 as pretrained model. We colloect interview from 1990 to 2008 of Steve Jobs as our fine tune dataset. Ask him about art, culture, technology, views on the world.",
  image: SJ,
  imageText: "main image description",
  linkText: "Discover Dataset and Model...",
};

const featuredPosts = [
  {
    title: "Elon Musk Style AI",
    date: "Dec 28",
    description:
      "This dataset contains interview and twitter data about Elon Musk.",
    image: Poster1,
    imageLabel: "Discover Dataset and Model...",
  },
  {
    title: "General Disease Analysis",
    date: "Dec 22",
    description:
      "This dataset contains image data from hospital with label.",
    image: Poster2,
    imageLabel: "Image Text",
  },
];


export default function Blog() {
  return (
    <Container maxWidth="lg" sx={{ mt: 16, backgroundColor: "#000" }}>
      <Header title="Blog" sections={sections} />
      <main>
        <MainFeaturedPost post={mainFeaturedPost} />
        <Grid container spacing={4}>
          {featuredPosts.map((post) => (
            <FeaturedPost key={post.title} post={post} />
          ))}
        </Grid>
      </main>
    </Container>
  );
}

import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import HomePage from "./views/homePage/HomePage";
import TalkPage from "./views/TalkPage/TalkPage";
import LoginPage from "./views/LoginPage/loginPage";
import PlayGround from "./views/PlayGround/PlayGround";
import Personal from "./views/PersonalPage/personalPage";
import Data from "./views/DataPage/DataPage";
import { CssBaseline, StylesProvider, ThemeProvider } from "@material-ui/core";
import theme from "./utils/Theme";
import { useState } from "react";
import Footer from "./components/Footer";

function App() {
  const [navBarHeight, setNavBarHeight] = useState(0);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <NavBar setNavBarHeight={setNavBarHeight} />
          <Routes>
            <Route
              path="/"
              element={<HomePage navBarOffset={navBarHeight} />}
            />
            <Route
              path="/talk"
              element={<TalkPage navBarOffset={navBarHeight} />}
            />
            <Route
              path="/login"
              element={<LoginPage navBarOffset={navBarHeight} />}
            />
            <Route
              path="/playground"
              element={<PlayGround navBarOffset={navBarHeight} />}
            />
            <Route
              path="/data"
              element={<Data navBarOffset={navBarHeight} />}
            />
            <Route
              path="/personal"
              element={<Personal navBarOffset={navBarHeight} />}
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;

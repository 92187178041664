import { useRef, useState } from "react";
import { Element } from "react-scroll";
import styled, { css } from "styled-components";
import {
  Column,
  PrimaryTitle,
  Row,
  SecondaryTitle,
  StyledColumn,
} from "../../Styles";
import { Spacer } from "../../utils/Spacer";
import Viewport from "../../utils/Viewport";
import LaunchImg from "../../assets/01_launch.png";
import DAO from "../../assets/02_Awaken-DAO.png";
import MiniGame from "../../assets/03_Mini_Game.png";
import Phase2 from "../../assets/04_Phase2.png";
import LaunchImgBGI from "../../assets/roadmap_1.png";
import DAOBGI from "../../assets/roadmap_2.png";
import MiniGameBGI from "../../assets/roadmap_3.png";
import Phase2BGI from "../../assets/roadmap_4.png";

import { Fade, Grow, Typography } from "@material-ui/core";
import MenuCloseIcon from "../../assets/menuCloseIcon.svg";
import { Colors } from "../../utils/Colors";

const Roadmap: React.FC = () => {
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();
  const [isOpened, setIsOpened] = useState(false);
  const [roadMapPage, setRoadMapPage] = useState(0);
  const [openFromOrigin, setOpenFromOrigin] = useState("0 0 0");

  const toggleOverlay = (id?: number) => {
    if (id) {
      setRoadMapPage(id);
    }
    setIsOpened(!isOpened);
  };

  const textContent = [
    {
      number: "01",
      title: "Launch",
      content: (
        <ul>
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            <li>...</li>
          </ContentDescription>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            <li>...</li>
          </ContentDescription>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            <li>...</li>
          </ContentDescription>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            <li>...</li>
          </ContentDescription>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            <li>...</li>
          </ContentDescription>
        </ul>
      ),
      backgroundImg: LaunchImgBGI,
    },
    {
      number: "02",
      title: "Build Steve Jobs",
      content: (
        <>
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            ...
          </ContentDescription>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove} bold>
            ...
          </ContentDescription>
        </>
      ),
      backgroundImg: DAOBGI,
    },
    {
      number: "03",
      title: "Build ELon Mask",
      content: (
        <>
          <ul>
            <ContentDescription isTabletAndAbove={isTabletAndAbove}>
              <li>...</li>
            </ContentDescription>
            <br />
            <ContentDescription isTabletAndAbove={isTabletAndAbove}>
              <li>...</li>
            </ContentDescription>
          </ul>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            ...
          </ContentDescription>
        </>
      ),
      backgroundImg: MiniGameBGI,
    },
    {
      number: "04",
      title: "Build Personal AL",
      content: (
        <>
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            ...
          </ContentDescription>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            ...
          </ContentDescription>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            ...
          </ContentDescription>
          <ul>
            <li>
              <ContentDescription isTabletAndAbove={isTabletAndAbove}>
                <li>...</li>
              </ContentDescription>
            </li>
            <br />
            <li>
              <ContentDescription isTabletAndAbove={isTabletAndAbove}>
                <li>...</li>
              </ContentDescription>
            </li>
            <br />
            <li>
              <ContentDescription isTabletAndAbove={isTabletAndAbove}>
                <li>...</li>
              </ContentDescription>
            </li>
          </ul>
        </>
      ),
      backgroundImg: Phase2BGI,
    },
  ];

  const ref = useRef() as React.MutableRefObject<HTMLImageElement>;

  return (
    <Element name="roadmap">
      <StyledColumn
        isDesktopAndAbove={isDesktopAndAbove}
        isTabletAndAbove={isTabletAndAbove}
      >
        <PrimaryTitle align="center" isTabletAndAbove={isTabletAndAbove}>
          ROAD MAP
        </PrimaryTitle>
        <SecondaryTitle align="center" isTabletAndAbove={isTabletAndAbove}>
          Our Journey Has Begun
        </SecondaryTitle>
        {isDesktopAndAbove ? <Spacer height={80} /> : <Spacer height={36} />}
        <RoadmapRow justify="space-between" ref={ref}>
          <Fade
            in={!isOpened}
            timeout={{
              enter: 1300,
              exit: 500,
            }}
          >
            <Row>
              <RoadmapColumn align="space-between">
                <RoadmapItem
                  src={LaunchImg}
                  onClick={() => {
                    setOpenFromOrigin(`0 0 0`);
                    toggleOverlay(0);
                  }}
                />
                {isTabletAndAbove ? (
                  <Spacer width={29} />
                ) : (
                  <Spacer width={5} />
                )}
                <RoadmapItem
                  src={DAO}
                  onClick={() => {
                    setOpenFromOrigin(`0 ${ref.current.clientHeight}px`);
                    toggleOverlay(1);
                  }}
                />
              </RoadmapColumn>
              {isTabletAndAbove ? <Spacer width={29} /> : <Spacer width={5} />}
              <RoadmapColumn align="space-between">
                <RoadmapItem
                  src={MiniGame}
                  onClick={() => {
                    setOpenFromOrigin(`${ref.current.clientWidth}px 0`);
                    toggleOverlay(2);
                  }}
                />
                {isTabletAndAbove ? (
                  <Spacer width={29} />
                ) : (
                  <Spacer width={5} />
                )}
                <RoadmapItem
                  src={Phase2}
                  onClick={() => {
                    setOpenFromOrigin(
                      `${ref.current.clientWidth}px ${ref.current.clientHeight}px
                      `
                    );
                    toggleOverlay(3);
                  }}
                />
              </RoadmapColumn>
            </Row>
          </Fade>
          <Grow
            in={isOpened}
            style={{
              transformOrigin: openFromOrigin,
            }}
            timeout={{
              enter: 1300,
              exit: 700,
            }}
            unmountOnExit={true}
          >
            <Overlay
              backgroundImg={
                isTabletAndAbove
                  ? textContent[roadMapPage]?.backgroundImg
                  : undefined
              }
              padding={isTabletAndAbove ? "88px" : "24px"}
            >
              {isTabletAndAbove ? (
                <Row justify="flex-end">
                  <img
                    src={MenuCloseIcon}
                    alt="menu close icon"
                    onClick={() => {
                      toggleOverlay();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Row>
              ) : null}
              <Row width="100%" justify="space-between">
                <ContentNumber isTabletAndAbove={isTabletAndAbove}>
                  {textContent[roadMapPage]?.number}
                </ContentNumber>
                {!isTabletAndAbove ? (
                  <img
                    src={MenuCloseIcon}
                    alt="menu close icon"
                    onClick={() => {
                      toggleOverlay();
                    }}
                    style={{ cursor: "pointer", width: "24px" }}
                  />
                ) : null}
              </Row>
              <ContentTitle isTabletAndAbove={isTabletAndAbove}>
                {textContent[roadMapPage]?.title}
              </ContentTitle>
              {isTabletAndAbove ? (
                <Spacer height={16} />
              ) : (
                <Spacer height={8} />
              )}
              <DescriptionContainer
                style={{ maxWidth: "590px" }}
                isTabletAndAbove={isTabletAndAbove}
              >
                {textContent[roadMapPage]?.content}
              </DescriptionContainer>
            </Overlay>
          </Grow>
        </RoadmapRow>
        {isDesktopAndAbove ? <Spacer height={160} /> : <Spacer height={64} />}
      </StyledColumn>
    </Element>
  );
};

export default Roadmap;

const DescriptionContainer = styled(Column)<{ isTabletAndAbove: boolean }>`
  ${({ isTabletAndAbove }) =>
    !isTabletAndAbove
      ? css`
          overflow: scroll;
          padding-right: 13px;
        `
      : null}
`;

const Overlay = styled(Column)<{ backgroundImg?: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-size: cover;
  border-radius: 20px;
  background-color: ${Colors.WHITE};
`;

const RoadmapColumn = styled(Column)`
  flex-shrink: 1;
  max-width: 543px;
`;

const RoadmapRow = styled(Row)`
  max-width: 1115px;
  width: 100%;
  position: relative;
`;

const RoadmapItem = styled.img<{ width?: string; height?: string }>`
  height: ${({ height }) => (height ? height : undefined)};
  border-radius: 20px;
  width: 100%;
  cursor: pointer;
`;

const ContentNumber = styled(Typography)<{ isTabletAndAbove: boolean }>`
  font-weight: 900;
  font-size: ${({ isTabletAndAbove }) => (isTabletAndAbove ? "58px" : "24px")};
  color: black;
`;

const ContentTitle = styled(Typography)<{ isTabletAndAbove: boolean }>`
  font-weight: 700;
  font-size: ${({ isTabletAndAbove }) => (isTabletAndAbove ? "32px" : "16px")};
  color: black;
`;

const ContentDescription = styled(Typography)<{
  isTabletAndAbove: boolean;
  bold?: boolean;
}>`
  font-size: ${({ isTabletAndAbove }) => (isTabletAndAbove ? "20px" : "12px")};
  color: black;
  font-weight: ${({ bold }) => (bold ? "bold" : "undefined")};
`;

import Viewport from "../../utils/Viewport";
import { TalkColumn, BlueTitle, BlackText, MenuText } from "../../Styles";
import { Element } from "react-scroll";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import head from "../../assets/avtar.png";
import Typography from "@mui/material/Typography";
import GroupsIcon from "@mui/icons-material/Groups";
import Box from "@mui/material/Box";

const User: React.FC = () => {
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();
  const [maximumLength, setmaximumLength] = React.useState<number>(50);

  return (
    <Element name="User">
      <TalkColumn
        isDesktopAndAbove={isDesktopAndAbove}
        isTabletAndAbove={isTabletAndAbove}
      >
        <Grid container spacing={1} mt = {7}>
          <Grid item xs={12}>
            <Avatar
              alt="Remy Sharp"
              src={head}
              sx={{ width: 256, height: 256 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              Bochen Dong
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
              dongbochen1218
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <GroupsIcon />
              <Typography variant="body1" component="h2">
                0 Followers, 0 Following
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </TalkColumn>
    </Element>
  );
};

export default User;

import Viewport from "../../utils/Viewport";
import { TalkColumn, BlueTitle, BlackText, MenuText } from "../../Styles";
import { Element } from "react-scroll";
import { Box } from "@mui/material";
import Slider from "@mui/material/Slider";
import * as React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import styled from "styled-components";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { Spacer } from "../../utils/Spacer";

const MenuSlider_R = styled(Slider)`
  color: #eb455f !important;
  my: 2;
`;

const MenuSlider_B = styled(Slider)`
  color: #4b6587 !important;
  my: 2;
`;

const MenuSlider = styled(Slider)`
  color: #343f56 !important;
  my: 2;
`;

const ControlMenu: React.FC = () => {
    const { isTabletAndAbove, isDesktopAndAbove } = Viewport();

    const [redTempValue, setredTempValue] = React.useState<number>(0.9);
    const [blueTempValue, setblueTempValue] = React.useState<number>(0.5);
    const [maximumLength, setmaximumLength] = React.useState<number>(50);
    const [model, setmodel] = React.useState("text-davinci-002");
    const [frequency, setFrequency] = React.useState<number>(0.0);
    const [Presence, setPresence] = React.useState<number>(0.0);

    const handleRedChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === "number") {
            setredTempValue(newValue);
        }
    };

    const handleBlueChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === "number") {
            setblueTempValue(newValue);
        }
    };

    const handleMaximumLengthChange = (
        event: Event,
        newValue: number | number[]
    ) => {
        if (typeof newValue === "number") {
            setmaximumLength(newValue);
        }
    };
    const handleFrequencyChange = (
      event: Event,
      newValue: number | number[]
    ) => {
      if (typeof newValue === "number") {
        setFrequency(newValue);
      }
    };

    const handlePresenceChange = (
      event: Event,
      newValue: number | number[]
    ) => {
      if (typeof newValue === "number") {
        setPresence(newValue);
      }
    };


    const handleModelChange = (event: SelectChangeEvent) => {
      setmodel(event.target.value as string);
    };


    return (
      <Element name="TextHistory">
        <Box sx={controlBoxStyle}>
          <Box sx={{ width: 250, mt: 5, mb: 2 }}>
            <MenuText isTabletAndAbove={isTabletAndAbove}>Model:</MenuText>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue="text-davinci-002"
                value={model}
                label="text-davinci-002"
                variant="standard"
                onChange={handleModelChange}
              >
                <MenuItem value={"text-davinci-002"}>text-davinci-002</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ width: 250 }}>
            <MenuText isTabletAndAbove={isTabletAndAbove}>
              Angry Jobs Temp: {redTempValue}
            </MenuText>
            <MenuSlider_R
              size="small"
              value={redTempValue}
              min={0.5}
              step={0.1}
              max={0.9}
              onChange={handleRedChange}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
            />
          </Box>

          <Box sx={{ width: 250 }}>
            <MenuText isTabletAndAbove={isTabletAndAbove}>
              Claim Jobs Temp: {blueTempValue}
            </MenuText>
            <MenuSlider_B
              size="small"
              value={blueTempValue}
              min={0.1}
              step={0.1}
              max={0.5}
              color="primary"
              onChange={handleBlueChange}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
            />
          </Box>

          <Box sx={{ width: 250 }}>
            <MenuText isTabletAndAbove={isTabletAndAbove}>
              Maximum Length: {maximumLength}
            </MenuText>
            <MenuSlider
              size="small"
              value={maximumLength}
              min={50}
              step={10}
              max={100}
              onChange={handleMaximumLengthChange}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
            />
          </Box>

          <Box sx={{ width: 250, my: 1 }}>
            <MenuText isTabletAndAbove={isTabletAndAbove}>
              Stop Sequence:
            </MenuText>
            <Input sx={{ width: 250 }} defaultValue="\n, END" />
          </Box>

          <Box sx={{ width: 250, my: 1 }}>
            <MenuText isTabletAndAbove={isTabletAndAbove}>
              Frequency penalty: {frequency}
            </MenuText>
            <MenuSlider
              size="small"
              value={frequency}
              min={0}
              step={0.1}
              max={2}
              onChange={handleFrequencyChange}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
            />
          </Box>

          <Box sx={{ width: 250 }}>
            <MenuText isTabletAndAbove={isTabletAndAbove}>
              Presence penalty: {Presence}
            </MenuText>
            <MenuSlider
              size="small"
              value={Presence}
              min={0}
              step={0.1}
              max={2}
              onChange={handlePresenceChange}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
            />
          </Box>
          <Spacer height={28}></Spacer>
          <Button variant="contained">Download Conversation</Button>
        </Box>
      </Element>
    );
};

const controlBoxStyle = {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#DDDDDD",
  width: "300px",
  alignItems: "center",
  height: "90vh",
  mx: 4,
  borderRadius: "16px",
  boxShadow: "rgba(0, 0, 0, 0.3) 0px 4px 12px",
};


export default ControlMenu;

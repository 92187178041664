import Viewport from "../../utils/Viewport";
import { StyledColumn} from "../../Styles";
import { Element } from "react-scroll";
import { Box } from "@mui/material";
import "../../components/Solgan/neonStyle.css";

const Soglan: React.FC = () => {
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();
  return (
    <Element name="Soglan">
      <StyledColumn
        isDesktopAndAbove={isDesktopAndAbove}
        isTabletAndAbove={isTabletAndAbove}
      >
        <Box mt={32}>
          <div className="neon"> &quot; Stay Hungry & Stay Foolish &quot; </div>
        </Box>
      </StyledColumn>
    </Element>
  );
};

export default Soglan;

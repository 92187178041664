import Viewport from "../../utils/Viewport";
import styled, { css } from "styled-components";
import {
  StyledColumn,
  PrimaryTitle,
  Row,
  SecondaryTitle,
  BigTitle,
  BlueTitle,
  Text,
} from "../../Styles";
import { Spacer } from "../../utils/Spacer";
import AwakenGif from "../../assets/GIF_Placeholder.png";
import { Element } from "react-scroll";

const Story: React.FC = () => {
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();
  return (
    <Element name="story">
      <StyledColumn
        isDesktopAndAbove={isDesktopAndAbove}
        isTabletAndAbove={isTabletAndAbove}
      >
        <PrimaryTitle align="center" isTabletAndAbove={isTabletAndAbove}>
          STORY
        </PrimaryTitle>
        <SecondaryTitle align="center" isTabletAndAbove={isTabletAndAbove}>
          About Us
        </SecondaryTitle>
        {isTabletAndAbove ? <Spacer height={80} /> : <Spacer height={36} />}
        <Content isDesktopAndAbove={isDesktopAndAbove}>
          <Gif
            src={AwakenGif}
            isDesktopAndAbove={isDesktopAndAbove}
            alt="Awaken Art GIF"
          />
          <Spacer height={40} width={62} />
          <StoryContainer isDesktopAndAbove={isDesktopAndAbove}>
            <BlueTitle isTabletAndAbove={isTabletAndAbove}>
              GPT-3 Based Steve Jobs
            </BlueTitle>
            <Spacer height={32} />
            <BigTitle isTabletAndAbove={isTabletAndAbove}>Behind TALK</BigTitle>
            <Spacer height={32} />
            <Text isTabletAndAbove={isTabletAndAbove}>
              We’ve trained a model based on GPT-3 which interacts in a
              conversational way. The dialogue format makes it possible to
              answer follow-up questions, admit its mistakes, challenge
              incorrect premises
            </Text>
            <Spacer height={24} />
            <Text isTabletAndAbove={isTabletAndAbove}>
              ChatGPT is a sibling model to InstructGPT, which is trained to
              follow instruction in a prompt and provide a detailed response
            </Text>
            <Spacer height={24} />
            <Text bold isTabletAndAbove={isTabletAndAbove}>
              Stay Hungry & Stay Foolish.
            </Text>
            <Spacer height={24} />
            <Text bold isTabletAndAbove={isTabletAndAbove}>
              You Had The Power All Along, My Dear.
            </Text>
          </StoryContainer>
        </Content>
        {isDesktopAndAbove ? <Spacer height={110} /> : <Spacer height={64} />}
      </StyledColumn>
    </Element>
  );
};

const StoryContainer = styled.div<{ isDesktopAndAbove: boolean }>`
  width: ${(props) => (props.isDesktopAndAbove ? "505px" : "100%")};
  min-width: ${(props) => (props.isDesktopAndAbove ? "505px" : "unset")};
`;

const Gif = styled.img<{ isDesktopAndAbove: boolean }>`
  max-width: 489px;
  ${({ isDesktopAndAbove }) =>
    isDesktopAndAbove
      ? css`
          min-width: 328px;
        `
      : css`
          width: 100%;
        `}

  flex-shrink: 1;
  border-radius: 20px;
`;

const Content = styled(Row)<{ isDesktopAndAbove: boolean }>`
  justify-content: space-between;
  flex-direction: ${(props) => (props.isDesktopAndAbove ? "row" : "column")};
  align-items: center;
  max-width: 1115px;
  width: 100%;
`;

export default Story;

import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Viewport from "../../utils/Viewport";
import WhiteCard from "./WhiteCard";
interface IHomePageProps {
  navBarOffset: number;
}

const Playgound: React.FC<IHomePageProps> = (props) => {
  const { navBarOffset } = props;
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();

  useEffect(() => {
    document.title = "CHAT - TALK";
  }, []);

  return (
    <HeroContainer offset={navBarOffset}>
      <WhiteCard />
      <Outlet />
    </HeroContainer>
  );
};

const HeroContainer = styled.div<{ offset: number }>`
  margin-top: -${(props) => props.offset}px;
`;

export default Playgound;

import Viewport from "../../utils/Viewport";
import styled, { css } from "styled-components";
import {
  StyledColumn,
  BigTitle,
  BlueTitle,
  Row,
  Text,
} from "../../Styles";
import { Spacer } from "../../utils/Spacer";
import SteveJobsGif from "../../assets/GIF_Placeholder.png";
import { Element } from "react-scroll";

const Describe: React.FC = () => {
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();
  return (
    <Element name="story">
      <StyledColumn
        isDesktopAndAbove={isDesktopAndAbove}
        isTabletAndAbove={isTabletAndAbove}
      >
        {isTabletAndAbove ? <Spacer height={120} /> : <Spacer height={36} />}
        <Content isDesktopAndAbove={isDesktopAndAbove}>
          <Gif
            src={SteveJobsGif}
            isDesktopAndAbove={isDesktopAndAbove}
            alt="SteveJobs GIF"
          />
          <DescribeContainer isDesktopAndAbove={isDesktopAndAbove}>
            <BlueTitle isTabletAndAbove={isTabletAndAbove}>
              GPT-3 Based Steve Jobs
            </BlueTitle>
            <Spacer height={24} />
            <BigTitle isTabletAndAbove={isTabletAndAbove}>
              Behind Steve Jobs AI
            </BigTitle>
            <Spacer height={24} />
            <Text isTabletAndAbove={isTabletAndAbove}>
              Open-Ai GPT-3 as pretrained model. We colloect interview from 1990
              to 2008 of Steve Jobs as our fine tune dataset. Ask him about art,
              culture, technology, views on the world.
            </Text>

            <Spacer height={12} />
            <Text bold isTabletAndAbove={isTabletAndAbove}>
              &quot;What is the meaning of life? &quot;
            </Text>

            <Spacer height={12} />
            <Text bold isTabletAndAbove={isTabletAndAbove}>
              We don&apos;t know, just start talk.
            </Text>
          </DescribeContainer>
        </Content>
        {isDesktopAndAbove ? <Spacer height={110} /> : <Spacer height={64} />}
      </StyledColumn>
    </Element>
  );
};

const DescribeContainer = styled.div<{ isDesktopAndAbove: boolean }>`
  width: ${(props) => (props.isDesktopAndAbove ? "505px" : "100%")};
  min-width: ${(props) => (props.isDesktopAndAbove ? "505px" : "unset")};
`;

const Gif = styled.img<{ isDesktopAndAbove: boolean }>`
  max-width: 489px;
  ${({ isDesktopAndAbove }) =>
    isDesktopAndAbove
      ? css`
          min-width: 328px;
        `
      : css`
          width: 100%;
        `}

  flex-shrink: 1;
  border-radius: 20px;
`;

const Content = styled(Row)<{ isDesktopAndAbove: boolean }>`
  justify-content: space-between;
  flex-direction: ${(props) => (props.isDesktopAndAbove ? "row" : "column")};
  align-items: center;
  max-width: 1115px;
  width: 100%;
`;

export default Describe;

import Viewport from "../../utils/Viewport";
import { TalkColumn, BlueTitle, BlackText, MenuText } from "../../Styles";
import { Element } from "react-scroll";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GroupsIcon from "@mui/icons-material/Groups";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const Overview: React.FC = () => {
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();

  return (
    <Grid container spacing={1} mx={3}>
      <Grid item xs={6}>
        <Card sx={{ minWidth: 275, maxWidth: 450, height: 140 }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              csv
            </Typography>
            <Typography variant="h6" component="div">
              Steve Jobs Conversation Data Set
            </Typography>
            <Typography variant="body2">
              A collection from past 20 years interview of Steve Jobs.
            </Typography>
          </CardContent>
          <CardActions>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              Dataset, Conversation
            </Typography>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card sx={{ minWidth: 275, maxWidth: 450, height: 140 }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              jpg, png
            </Typography>
            <Typography variant="h6" component="div">
              Lumbar disc herniation
            </Typography>
            <Typography variant="body2">
              Images of lumbar disc herniation collect from the hospital.
            </Typography>
          </CardContent>
          <CardActions>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              Dataset, Medical, Segmentaion Detect
            </Typography>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card sx={{ minWidth: 275, maxWidth: 450, height: 140 }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              csv
            </Typography>
            <Typography variant="h6" component="div">
              Multimodal Single-Cell Integration
            </Typography>
            <Typography variant="body2">single-cell multiomics data</Typography>
          </CardContent>
          <CardActions>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              Dataset, Medical, Classification
            </Typography>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card sx={{ minWidth: 275, maxWidth: 450, height: 140 }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              Model
            </Typography>
            <Typography variant="h6" component="div">
              Fine-tuned Steve Jobs
            </Typography>
            <Typography variant="body2">Using interview data and book data.</Typography>
          </CardContent>
          <CardActions>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              GPT-3 davinci-2 model
            </Typography>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card sx={{ minWidth: 275, maxWidth: 450, height: 140 }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              csv
            </Typography>
            <Typography variant="h6" component="div">
              Elon Musk Conversation Data Set
            </Typography>
            <Typography variant="body2">
              A collection from recent public interview of Elon Musk.
            </Typography>
          </CardContent>
          <CardActions>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              Dataset, Conversation
            </Typography>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card sx={{ minWidth: 275, maxWidth: 450, height: 140 }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              csv
            </Typography>
            <Typography variant="h6" component="div">
              Steve Jobs Book
            </Typography>
            <Typography variant="body2">A collection from data generate from chatGPT after read book.</Typography>
          </CardContent>
          <CardActions>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              csv file, Dataset, Conversation
            </Typography>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Overview;

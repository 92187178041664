import React from "react";
import styled from "styled-components";
import { Column } from "../Styles";
import { Spacer } from "../utils/Spacer";
import Viewport from "../utils/Viewport";
import { SocialLinks } from "./NavBar/NavbarComponents";
import Logo from "../assets/logo.png";
import { Colors } from "../utils/Colors";

function Footer() {
  const { isTabletAndAbove } = Viewport();
  return (
    <StyledColumn align="center">
      <Spacer height={48} />
      {isTabletAndAbove ? <Spacer height={100} /> : null}
      <LogoPlaceHolder src={Logo} />
      <Spacer height={40} />
      <SocialLinks />
      {isTabletAndAbove ? <Spacer height={124} /> : <Spacer height={92} />}
    </StyledColumn>
  );
}

const LogoPlaceHolder = styled.img`
  height: 100px;
  width: 100px;
`;

const StyledColumn = styled(Column)`
  background-color: ${Colors.BLACK};
`;

export default Footer;

import Viewport from "../../utils/Viewport";
import { TalkColumn, BlueTitle, BlackText, MenuText } from "../../Styles";
import { Element } from "react-scroll";
import { Box } from "@mui/material";
import TextArea from "./TextArea";
import ControlMenu from "./ControlMenu";
import { Spacer } from "../../utils/Spacer";
import * as React from "react";

const infoBoxStyle = {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#000",
  width: "100%",
  minHeight: 200,
  mt: 16,
  justifyContent: "center",
  alignItems: "center",
};

const conversationBoxStyle = {
  display: "flex",
  flexDirection: "row",
  backgroundColor: "#000",
  width: "100%",
  minHeight: 200,
  justifyContent: "center",
  alignItems: "center",
};


const WhiteCard: React.FC = () => {
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();
  const [maximumLength, setmaximumLength] = React.useState<number>(50);

  return (
    <Element name="TalkHistory">
      <TalkColumn
        isDesktopAndAbove={isDesktopAndAbove}
        isTabletAndAbove={isTabletAndAbove}
      >
        <Box sx={infoBoxStyle}>
          <Spacer height={48} />
          <Box sx={conversationBoxStyle}>
            <ControlMenu></ControlMenu>
            <TextArea></TextArea>
          </Box>

          <Spacer height={48}></Spacer>
        </Box>
      </TalkColumn>
    </Element>
  );
};

export default WhiteCard;

import Viewport from "../../utils/Viewport";
import styled from "styled-components";
import { TalkColumn, BlueTitle, BlackText, MenuText } from "../../Styles";
import { Element } from "react-scroll";
import { Box } from "@mui/material";
import TextArea from "./TextArea";
import ControlMenu from "./ControlMenu";
import { Spacer } from "../../utils/Spacer";
import SJ from "../../assets/SJ.png";
import * as React from "react";


const infoBoxStyle = {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  width: "100%",
  minHeight: 200,
  justifyContent: "center",
  alignItems: "center",
};

const conversationBoxStyle = {
  display: "flex",
  flexDirection: "row",
  backgroundColor: "#fff",
  width: "100%",
  minHeight: 200,
  justifyContent: "center",
  alignItems: "center",
};



const SteveJobs = (): JSX.Element => (
  <SJImage src={SJ} alt="Steve Jobs image" />
);

const SJImage = styled.img`
  width: 100%;
`;

const WhiteCard: React.FC = () => {
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();
  return (
    <Element name="TalkHistory">
      <TalkColumn
        isDesktopAndAbove={isDesktopAndAbove}
        isTabletAndAbove={isTabletAndAbove}
      >
        <Box sx={infoBoxStyle}>
          {/*Image and text*/}
          <Box
            sx={{
              display: "block",
              width: "55vw",
            }}
          >
            <SteveJobs></SteveJobs>
          </Box>
          {/*Text */}
          <Box
            sx={{
              display: "block",
              width: "50vw",
            }}
          >
            <BlueTitle isTabletAndAbove={isTabletAndAbove}>
              Talk with Steve Jobs
            </BlueTitle>
            <Spacer height={24} />
            <BlackText isTabletAndAbove={isTabletAndAbove}>
              Here we have a{" "}
              <span style={{ color: "#EB455F", fontSize: "30px" }}>Red </span>{" "}
              Jobs and
              <span style={{ color: "#30475E", fontSize: "30px" }}> Bule </span>
              Jobs.
            </BlackText>
            <Spacer height={15}></Spacer>

            <BlackText isTabletAndAbove={isTabletAndAbove}>
              The{" "}
              <span style={{ color: "#EB455F", fontSize: "30px" }}>Red </span>{" "}
              ones are more dynamic, and the{" "}
              <span style={{ color: "#30475E", fontSize: "30px" }}> Bule </span>{" "}
              ones are more in line with the facts.
            </BlackText>

            <Spacer height={15}></Spacer>
            <BlackText isTabletAndAbove={isTabletAndAbove}>
              Click and select the conversation you like. This convsersation
              will add to your account for your personal preferences.
            </BlackText>
          </Box>
          <Spacer height={48} />
          <Box sx={conversationBoxStyle}>
            <ControlMenu></ControlMenu>
            <TextArea></TextArea>
          </Box>

          <Spacer height={48}></Spacer>
        </Box>
      </TalkColumn>
    </Element>
  );
};

export default WhiteCard;

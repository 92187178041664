import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    type: "dark",
    secondary: {
      main: "#DA0037",
    },
    primary: {
      main: "#4B6587",
    },
    background: {
      default: "#000000",
    },
  },

  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export default theme;

import { useEffect, useState } from "react";
import styled from "styled-components";
import Viewport from "../../utils/Viewport";
import Box from "@mui/material/Box";
import User from "./User"
import Grid from "@mui/material/Grid";
import Overview from "./Overview";
import Finetune from "./finetune";
import Button from "@mui/material/Button";
interface IHomePageProps {
  navBarOffset: number;
}

const PersonalPage: React.FC<IHomePageProps> = (props) => {
  const { navBarOffset } = props;
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();
  const [OverView, setOverView] = useState(false);
  const [FineTune, setFineTune] = useState(false);


  useEffect(() => {
    document.title = "Personal - TALK";
  }, []);

  return (
    <HeroContainer offset={navBarOffset}>
      <Box mx={12}>
        <Grid container spacing={4} mt={13}>
          <Grid item xs={3}>
            <User></User>
          </Grid>

          <Grid item xs={9}>
            <Grid container spacing={1} mx={3}>
              <Grid item xs={12}>
                <Button
                  variant="text"
                  onClick={() => {
                    setOverView(true);
                    setFineTune(false);
                  }}
                >
                  Overview
                </Button>
                <Button variant="text">DataSet</Button>
                <Button variant="text">Model</Button>
                <Button
                  variant="text"
                  onClick={() => {
                    setOverView(false);
                    setFineTune(true);
                  }}
                >
                  FineTune
                </Button>
              </Grid>
            </Grid>
            {OverView ? <Overview></Overview> : <Finetune></Finetune>}
          </Grid>
        </Grid>
      </Box>
    </HeroContainer>
  );
};

const HeroContainer = styled.div<{ offset: number }>`
  margin-top: -${(props) => props.offset}px;
`;

export default PersonalPage;
